'use client';

import * as Sentry from '@sentry/nextjs';
import { NextPageContext } from 'next';
import Error from 'next/error';
import React from 'react';
interface CustomErrorComponentProps {
  statusCode: number;
}
class CustomErrorComponent extends React.Component<CustomErrorComponentProps> {
  static async getInitialProps(contextData: NextPageContext) {
    // In case this is running in a serverless function, await this in order to give Sentry
    // time to send the error before the lambda exits
    await Sentry.captureUnderscoreErrorException(contextData);

    // This will contain the status code of the response
    return Error.getInitialProps(contextData);
  }
  render() {
    const {
      statusCode
    } = this.props;
    return <Error statusCode={statusCode} data-sentry-element="Error" data-sentry-component="CustomErrorComponent" data-sentry-source-file="global-error.tsx" />;
  }
}
export default CustomErrorComponent;